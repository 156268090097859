import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";

export interface ApiRequestReggData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
  token?: string;
}

export interface Product {
  productId: number;
  title: string;
  content: string;
  basicprice: string;
  advanceprice: string;
  premiumprice: string;
}

export interface Item {
  id: number;
  attributes: {
    name: string;
    title: string;
    price: string;
    description: string;
    content: string;
    image: {
      url: string;
    }
  }
}

export interface ProductListItem {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  name: string
  price: string
  description: string
  product_type: string
  image: Image
}

export interface Image {
  url: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  price: number;
  productList: ProductListItem[];
  loginToken: string;
  allProducts: [];
  // Customizable Area End
}

export interface PriceList {
  id: number;
  title: string;
  description: string;
  subtitle: string;
  isSelect: false;
  cwPrice: string;
}
export interface Cart {
  id: number;
  image: number;
  header: string;
  description: string;
  productPrice: number;
  totalPrice: number;
  quantity: number;
  priceList: Array<PriceList>;
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultitieredProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productListApiCallId: string = "";
  allProductsApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      price: 0,
      productList: [],
      loginToken: "",
      allProducts: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      if (value) {
        this.setState({ loginToken: value.meta.token })
        this.getAllProducts()
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.allProductsApi) {
        this.setState({ allProducts: responseJson.data });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getAllProducts();
  }



 
  apiCall = async (data: ApiRequestReggData) => {
    const authToken= await getStorageData('authToken')
    const token = authToken
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData" ? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  getAllProducts = async () => {
    this.allProductsApi = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.productListApiEndPoint,
    });
  };

  handleCardNavigation = (productId: number) => {
    setStorageData("productId", productId)
    this.toNavigate("Multitieredpricing")
  };

  handleCart = () => {
    this.toNavigate("MultitieredCart")
  }

  toNavigate = (screen: string, params?: Record<string, string>) => {
    const navMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      screen
    );
    navMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if (params) {
      const raiseMsg: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMsg.addData(getName(MessageEnum.SessionResponseData), params);
      navMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMsg);
    }
    this.send(navMsg)
  }
  // Customizable Area End
}
