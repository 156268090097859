import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
export interface PriceList {
  title: string;
  description: string;
  subtitle: string;
  isSelect: false;
  cwPrice: string;
}

export interface Cart {
  id: number;
  image: number;
  header: string;
  description: string;
  productPrice: number;
  totalPrice: number;
  quantity: number;
  priceList: Array<PriceList>;
}

export interface CartItem {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  id: number
  product_id: number
  multitieredpricing_id: number
  cart_id: number
  quantity: number
  total_price: string
  multitieredpricing_attributes: MultitieredpricingAttributes
}

export interface MultitieredpricingAttributes {
  id: number
  product_id: number
  name: string
  quality_range: string
  price: string
  description: string
  format_type: string
  product_attributes: ProductAttributes
}

export interface ProductAttributes {
  name: string
  price: string
  description: string
  product_type: string
  image: Image
}

export interface Image {
  url: string
}

export interface ApiRequestReggData {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
  token?: string;
}

export interface MultitieredCartItem {
  id: number;
  attributes: {
    multitieredpricing_attributes: {
      quality_range: string;
      product_attributes: {
        image: {
          url: string;
        };
        name: string;
        description: string;
      };
    };
    quantity: number;
    total_price: number;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cartList: CartItem[];
  tokenGetCart: string;
  totalAmount: number;
  price: string;
  quantity: number;
  totalCost: number;
  nothingInCart: boolean;
  title: string;
  content: string;
  allCartItem: MultitieredCartItem[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultitieredCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cartListApiCallId: string = "";
  removeCartItemApiCallId: string = "";
  updateCartItemApiCallId: string = "";
  allCartItemApi: string = "";
  removeCartItemApi: string = "";
  updateCartItemApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cartList: [],
      tokenGetCart: "",
      totalAmount: 0,
      price: "",
      quantity: 1,
      totalCost: 0,
      nothingInCart: false,
      title: "",
      content: "",
      allCartItem: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const getToken = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({ tokenGetCart: getToken })
      this.getCartList()
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.cartListApiCallId) {
      if (responseJson && responseJson.data) {
        this.setState({ cartList: responseJson.included, totalAmount: responseJson.data.attributes.total_cost })
      }
    }
    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.allCartItemApi) {
        this.setState({
          allCartItem: responseJson.included,
          totalCost: responseJson.data.attributes.total_cost,
        });
      }
      this.handleResponse(apiRequestCallId)
    }

    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    this.getAllCartItem();
  }

  handleResponse = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.removeCartItemApiCallId) {
      this.getCartList()
    }
    if (apiRequestCallId === this.updateCartItemApiCallId) {
      this.getCartList()
    }
    if (apiRequestCallId === this.removeCartItemApi) {
      this.getAllCartItem();
    }
    if (apiRequestCallId === this.updateCartItemApi) {
      this.getAllCartItem();
    }
  }

  apiCalling = async (data: ApiRequestReggData) => {
    const { contentType, method, endPoint, body, type } = data;
    const token1 = await getStorageData("authToken");
    const headers = {
      "Content-Type": contentType,
      token: token1,
    };
    const request1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headers));
    request1.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 
    endPoint);
    request1.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData" ? request1.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : request1.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request1.id, request1);
    return request1.messageId;
  };

  getAllCartItem = async () => {
    this.allCartItemApi = await this.apiCalling({
      contentType: "application/json",
      method: "GET",
      endPoint: `${configJSON.createAndGetCartListApiEndPoint}`,
    });
  };

  handleBackButton = () => {
    this.toNavigate("Multitieredpricing");
  };

  handleBuyButton = () => {
    this.toNavigate("MultitieredProduct");
  };

  handlePlus = (cartItemId: number, quantity: number) => {
    const newQuantity = quantity + 1;
    this.handleUpdateItem(cartItemId, newQuantity);
  };

  handleMinus = (cartItemId: number, quantity: number) => {
    const newQuantity = quantity - 1;
    if (newQuantity < 1) {
      this.handleRemoveItem(cartItemId);
      return false;
    }
    this.handleUpdateItem(cartItemId, newQuantity);
  };

  handleRemoveItem = async (cartItemId: number) => {
    this.removeCartItemApi = await this.apiCalling({
      contentType: "application/json",
      method: "GET",
      endPoint: `${configJSON.removeCartItemApiEndPoint}${cartItemId}`,
    });
  };

  handleUpdateItem = async (cartItemId: number, quantity: number) => {
    this.updateCartItemApi = await this.apiCalling({
      contentType: "application/json",
      method: "GET",
      endPoint: `/${configJSON.updateCartListApiEndPoint}${quantity}&cart_item_id=${cartItemId}`,
    });
  };

  toNavigate = (path: string) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  getCartList(): boolean {

    const header = {
      token: this.state.tokenGetCart,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cartListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAndGetCartListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  removeCartItem(productId: string): boolean {

    const header = {
      token: this.state.tokenGetCart,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeCartItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeCartItemApiEndPoint + productId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  updateCartItem(productId: string, quantity: number): boolean {
    if (quantity <= 0) {
      this.removeCartItem(productId)
      return false
    }
    const header = {
      token: this.state.tokenGetCart,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCartItemApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCartListApiEndPoint + quantity + "&cart_item_id=" + productId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  capitalizeTextForDetail(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  // Customizable Area End
}